/*

import { Home, AccountCircle, Settings, AddCircle } from "../icons";

url = "/assets/imgs/avatar2.jpg"
const categories = [
    { Name: "Manufacturing", Icon: FactoryIcon, Disabled: true },
    { Name: "Retail", Icon: RetailIcon, Disabled: true },
    { Name: "Building Materials", Icon: BuildingMaterialsIcon, Disabled: true },
    { Name: "Life Science", Icon: LocalHospitalIcon, Disabled: false },
    { Name: "Media", Icon: MediaIcon, Disabled: false },
    { Name: "Automotive", Icon: MediaIcon, Disabled: false },
    { Name: "High Tech", Icon: MediaIcon, Disabled: false },
    { Name: "Goverment", Icon: MediaIcon, Disabled: false },
    { Name: "Real Estate", Icon: MediaIcon, Disabled: false },
    { Name: "Banking", Icon: MediaIcon, Disabled: false },
    { Name: "Insurance", Icon: MediaIcon, Disabled: false },
    { Name: "Consumer Products", Icon: LocalGroceryStoreIcon, Disabled: false },
    { Name: "Mill Products", Icon: MediaIcon, Disabled: false },
    { Name: "Professional Services", Icon: MediaIcon, Disabled: false },
    { Name: "Utilities", Icon: MediaIcon, Disabled: false },
    { Name: "Wholesale Distribution", Icon: MediaIcon, Disabled: false },
    { Name: "Cargo, Transportation and Logistics", Icon: TransportationIcon, Disabled: false },
    { Name: "Defence and Security", Icon: MediaIcon, Disabled: false },
    { Name: "Education and Research", Icon: MediaIcon, Disabled: false },
    { Name: "Passenger Travel and Leisure", Icon: MediaIcon, Disabled: false },
    { Name: "Sports and Entertainment", Icon: MediaIcon, Disabled: false },
    { Name: "Oil, Gas and Energy", Icon: MediaIcon, Disabled: false },
    { Name: "Fashion", Icon: MediaIcon, Disabled: false },
    { Name: "Engineering, Construction and Operations", Icon: MediaIcon, Disabled: false },
    { Name: "Telco", Icon: SatelliteIcon, Disabled: true },
];


const CharacterDetails = ({ iconData }) => {

    const [character, setCharacter] = createSignal(iconData);
    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const handlePopoverOpen = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = () => Boolean(anchorEl());


    return (
        <>

            <Show
                when={logoUrl}
                fallback={
                    <Avatar
                        sx={{
                            width: 30, height: 30, px: 1, py: 1, margin: 1,
                            bgcolor: grey[300],
                        }}
                        variant="rounded"

                    >
                        No logo
                    </Avatar>
                }
            >
                <Avatar
                    sx={{
                        width: 45, height: 45, px: 1, py: 1
                                        bgcolor: grey[300],
                    }}
                    variant="rounded"
                    image={logoUrl}
                    alt="Anonymized"
                >

                </Avatar>

            </Show>
            <Typography
                aria-owns={open() ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                
                <Show when={character()?.Disabled == true}

                >
                    <div>
                        <IconButton color="success">
                            {character()?.Icon}
                        </IconButton>
                    </div>
                </Show>


            </Typography>

        </>
    )
};

 
interface HomePageIconProps {
    name: string;
    homePageIcon: JSX.Element;
    handleEvent: (event: MouseEvent) => void;
    type?: string;
}


const HomePageIcon = ({
    name,
    homePageIcon,
    handleEvent,
    type = "",
}: HomePageIconProps) => {
    let iconColor = ((type == "candidate")) ? green[300] : blue[300];
    
    return (
        <>
            <Button
                sx={{
                    width: 140,
                    height: 100,

                    bgcolor: iconColor,
                }}
                variant="contained"
                onClick={handleEvent}
            >
                <Stack direction="column">
                    {homePageIcon}
                    <Typography variant="body2">{name}</Typography>
                </Stack>
            </Button>
        </>
    );
}


export const CandidatesList = () => {


    return (
        <>
            <Stack
                direction="column"
                alignItems="left"
                justifyContent="space-between"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
            >
                <ShowHeading header={header} pxValue={0} size="small" />

                <Stack direction="row" spacing={1} sx={{ px: 1 }} alignItems="center" justifyContent="space-between">

                    <For
                        each={categories}
                        fallback={<p>Loading...</p>}
                    >
                        {(character) => <CharacterDetails iconData={character} />}
                    </For>

                </Stack>
            </Stack>
        </>
    )
}
*/

import {  createSignal, Show } from "solid-js";
import { Stack, Typography, Icon, Popover, Avatar, Divider, Card, Grid, Box } from "@suid/material";

import {   grey,   } from "@suid/material/colors";
 
import { PersonIcon, CalendarIcon, OnsiteIcon, LocationIcon, RateIcon, QuestionMarkIcon } from '../icons';
import { StarRating } from './SmallShowStars';
import { isMedium, isLarge, isExtraLarge }  from "../../../utils/sizeUtil"


import { getText } from '../SmallComponents/Translate'


const categories = [
    { Name: "Meta", PingedBy: "Ted Winner", logoUrl: '/assets/imgs/facebookpng.png', Disabled: true },
    { Name: "Netflix", PingedBy: "Withny Jacky", logoUrl: '/assets/imgs/Netflix_logo_PNG1.png', Disabled: true },
    { Name: "Mærsk", PingedBy: "Ant Body", logoUrl: '/assets/imgs/Maersk_logo_PNG4.png', Disabled: true },
    { Name: "Tesla", PingedBy: "Aslam Shai", logoUrl: '/assets/imgs/Tesla_logo_PNG3.png', Disabled: false },

];


const AdditonalCandidateInfo = (jobStart: string, jobEnd: string, onSiteText: string, wfStatus: string, matchPct: number, locationCountry?: string, locationCity?: string, rateValue?: number, rateCurrency?: string, rating?: number) => {
    const txtMatch = getText("smallcomponent", "match")
    const txtQuantity = getText("smallcomponent", "quantityhourslabel")
    return (
        <>
            {/* Use a card if we have a matchPct */}
            <Show when={matchPct} >


                <Card>

                    {/* Show box with rating, price and match pct */}
                    <Box
                        sx={{
                            bgcolor: "background",
                            borderColor: "text.primary",
                            borderRadius: 2,
                            p: 2,
                            minWidth: 200,
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="left"
                            justifyContent="space-between"     >
                            <Box sx={{ color: "text.secondary" }}>{txtMatch()}</Box>
                            <Stack
                                direction="row"
                                alignItems="right" >

                                <RateIcon sx={{
                                    color: "text.secondary",
                                    display: "inline",
                                    fontWeight: "bold",
                                    mx: 0.5,
                                    fontSize: 14,
                                }} />

                                <Typography variant="body2" color="success.dark"  >{rateValue} {rateCurrency}</Typography>

                            </Stack>
                        </Stack>

                        <Box sx={{ color: "success.dark", fontSize: 34, fontWeight: "medium" }}>
                            {matchPct}%
                        </Box>

                        <Box sx={{ color: "success.dark", display: "inline", fontSize: 14 }}>
                            <Show when={rateValue} >
                                <Stack
                                    direction="row">
                                    <StarRating size="small" rating={rating as number} /> ({rating})

                                </Stack>
                            </Show>

                        </Box>
                    </Box>
                </Card>

                <Card>
                    <Box sx={{
                        bgcolor: "background",
                        borderColor: "text.primary",
                        borderRadius: 2,
                        p: 2,
                        minWidth: 200,
                    }}  >
                        <Show when={jobStart} >
                            <Typography variant="body1" color="disabled" gutterBottom>{txtQuantity()}</Typography>
                            <Stack direction="row">
                                <CalendarIcon fontSize="small" color="disabled" />
                                <Typography variant="body2" sx={{ px: 1 }} color="disabled">{jobStart} </Typography>
                                <Show when={jobEnd} >
                                    <Typography variant="body2" color="disabled">to {jobEnd}</Typography>
                                </Show>
                            </Stack>
                            <Stack direction="row">
                                <OnsiteIcon fontSize="small" color="disabled" />
                                <Typography variant="body2" sx={{ px: 1 }} color="disabled">{onSiteText}</Typography>
                            </Stack>
                        </Show>
                    </Box>
                </Card>

            </Show>

            {/* Display additional information in rows instead of in a card*/}
            <Show when={!matchPct} >
                <Show when={wfStatus} >

                    <Stack
                        direction="row" sx={{
                            color: "text.secondary",
                            fontWeight: "bold",
                            mx: 0.5,
                            fontSize: 14,
                        }}>

                        <Typography variant="body1" color="success.dark">{wfStatus}</Typography>

                    </Stack>
                </Show>


                <Stack
                    direction="row">
                    <Show when={jobStart} >
                        <CalendarIcon fontSize="small" color="disabled" />
                        <Typography variant="body2" sx={{ px: 1 }} color="disabled">{jobStart} </Typography>
                        <Show when={jobEnd} >
                            <Typography variant="body2" color="disabled">to {jobEnd}</Typography>
                        </Show>
                    </Show>
                </Stack>
                <Show when={rateValue} >
                    <Stack
                        direction="row">
                        <RateIcon fontSize="small" color="disabled" />

                        <Typography variant="body2" sx={{ px: 1 }} color="text.secondary">{rateValue} {rateCurrency}</Typography>

                    </Stack>
                </Show>
                <Show when={onSiteText} >
                    <Stack
                        direction="row">
                        <OnsiteIcon fontSize="small" color="disabled" />
                        <Typography variant="body2" sx={{ px: 1 }} color="disabled">{onSiteText}</Typography>
                    </Stack>
                </Show>
                <Show when={locationCity} >
                    <Stack
                        direction="row">
                        <LocationIcon fontSize="small" color="disabled" />
                        <Typography variant="body2" sx={{ px: 1 }} color="disable">{locationCountry} {locationCity}</Typography>
                    </Stack>
                </Show>

            </Show>

        </>
    )
}


const ShowAvatar = (showLogo: boolean, anonymized: boolean, logoUrl: string, candidateName: string, popoverText: string) => {
   
    const handlePopoverOpen = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);


    return (
        <>
            <Show
                when={showLogo}
            >
                <Show
                    when={anonymized}
                    fallback={

                        <Show
                            when={logoUrl}
                            fallback={

                                <Show
                                    when={!candidateName}
                                    fallback={
                                        <Avatar onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                                            <Icon><PersonIcon /></Icon>
                                        </Avatar>
                                    }
                                >
                                    <Avatar  >
                                        <Icon><QuestionMarkIcon /></Icon>
                                    </Avatar>
                                </Show>
                            }
                        >

                            <Avatar
                                alt={candidateName}
                                src={logoUrl}
                                onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}
                            >
                            </Avatar>
                        </Show>
                    }
                >
                    <Avatar
                        sx={{
                            width: 30, height: 30, px: 1, py: 1, margin: 1,
                            bgcolor: grey[300],
                        }}

                        onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}
                    >
                        A
                    </Avatar>
                </Show>
            </Show>

            <Popover
                id="mouse-over-popover" sx={{ pointerEvents: "none" }} open={openPopover()}
                anchorEl={anchorEl()}
                anchorOrigin={{ vertical: "bottom", horizontal: "left", }}
                transformOrigin={{ vertical: "top", horizontal: "left", }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ px: 2, py: 2, }} variant="body2" align="center" gutterBottom> {popoverText}</Typography>
            </Popover>
        </>
    )
}

interface CandidateAvatarProps {
    candidateName?: string;
    logoUrl?: string;
    jobName?: string;
    jobRole?: string;
    jobStartDate: string;
    jobEndDate: string;
    anonymized?: boolean;
    showLogo?: boolean;
    onSiteText?: string;
    matchPct?: number
    rating?: number;
    rateValue?: number;
    rateCurrency?: string;
    locationCountry?: string;
    locationCity?: string;
    wfStatus?: string;
    layout?: string;
}
const CandidateAvatar = ({
    candidateName = "",
    logoUrl = "",
    jobRole = "",
    jobName,
    jobStartDate, jobEndDate,
    anonymized = false,
    showLogo = true,
    wfStatus = "",
    onSiteText = "",
    matchPct = 0,
    rating = 0,
    rateValue = 0,
    rateCurrency = "",
    locationCountry = "",
    locationCity = "",
    layout = "column",
}: CandidateAvatarProps) => {
    

    const jobStart = jobStartDate //jobStartDate.toDateString();
    const jobEnd = jobEndDate //jobEndDate.toDateString();
    let popoverText = candidateName;

    const txt1 = getText("default", "candidate")
    const txt2 = getText("default", "candidatenopicture")
    const txt3 = getText("default", "candidatepicturenoupload")

    if (anonymized) {
        logoUrl = "";
        candidateName = txt1() as string
        popoverText = txt2() as string
    } else if (!logoUrl) {
        popoverText = txt3() as string
    }
    return (
        <>


            {/* Show company logo or a letter based Avatar */}
            <Stack {...isExtraLarge() ? { direction: 'row' } : { direction: 'column' }} alignItems="left" sx={{ margin: 1, display: 'flex' }}
            >
                <Show
                    when={layout != "grid"}
                >

                    {ShowAvatar(showLogo, anonymized, logoUrl, candidateName, popoverText)}

                </Show>

                {/* Show information */}
                <Stack
                    direction="column">

                    {/* Simple list view */}
                    <Show
                        when={layout == "grid"}
                        fallback={
                            <>

                                <Stack direction="column" alignItems="left" sx={{ margin: 1, display: 'flex' }}
                                >
                                    <Typography variant="h6">{candidateName}</Typography>
                                    <Typography variant="body1" color="text.secondary">{jobRole}</Typography>
                                    <Typography variant="body1" color="text.secondary">{jobName}</Typography>

                                    {AdditonalCandidateInfo(jobStart, jobEnd, onSiteText, wfStatus, matchPct, locationCountry, locationCity, rateValue, rateCurrency, rating)}
                                </Stack>
                            </>

                        }
                    >
                        {/* Complex in a row */}
                        <Grid container margin={1} spacing={2} {...isExtraLarge() ? { direction: 'row' } : { direction: 'column' }}>

                            <Stack
                                spacing={1}
                                margin={1}
                                {...isExtraLarge() ? { direction: 'row' } : { direction: 'column' }}
                                alignItems="left"
                                justifyContent="space-between" >

                                <Stack direction="column" alignItems="left" sx={{ margin: 1, display: 'flex' }}
                                >
                                    <Stack direction="row" alignItems="left" sx={{ margin: 1, display: 'flex' }}
                                    >
                                        <Typography sx={{ px: 3 }} variant="h6">{candidateName}</Typography>

                                        {ShowAvatar(showLogo, anonymized, logoUrl, candidateName, popoverText)}

                                    </Stack>
                                    <Typography variant="body1" color="text.secondary">{jobRole}</Typography>
                                    <Typography variant="body1" color="text.secondary">{jobName}</Typography>

                                </Stack>

                                {AdditonalCandidateInfo(jobStart, jobEnd, onSiteText, wfStatus, matchPct, locationCountry, locationCity, rateValue, rateCurrency, rating)}
                            </Stack>
                        </Grid>

                    </Show>
                </Stack>
            </Stack>

            <Divider />

        </>
    );
}

export { CandidateAvatar } 