// Define constants for repeated values
import {  common, blue, green, grey, blueGrey } from "@suid/material/colors";
 
export const CARD_MARGIN = 2;
export const CARD_DISPLAY = 'flex';
export const CARD_COLOR_L1 = common.black;//grey[900]; //blueGrey[900]; //common.black[700];
export const CARD_COLOR_L2 = common.black// grey[200];//grey[800]; //blueGrey[800];
export const CARD_COLOR_L3 = grey[800];//grey[700]; //blueGrey[700]; //grey[800];
export const CARD_COLOR_BLUE = blue[600];
export const CARD_COLOR_GREEN = green[100];
export const BG_COLOR = 'background.default'[10];
export const APPBAR_COLOR = '#03031C';

export const BLUE_COLOR = blue[600];
export const GREY_COLOR = grey[200];
